<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div>
          <b-card header-tag="header" footer-tag="footer">
            <b-row slot="header">
              <b-col><h4 class="card-title">New Scan</h4></b-col>
            </b-row>
            <b-card-text>
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                  id="input-group-1"
                  label="Name:"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.name"
                    type="text"
                    required
                    placeholder="Name"
                  ></b-form-input>
                </b-form-group>

                <b-form-group v-for="(item, index) in form.params"
                              :id="'input-group-' +  index"
                              :label-for="'input-' + index"
                              v-bind:key="index"
                >
                  <b-row>
                    <b-col cols="5">
                      <b-form-select :options="options"
                                     v-model="form.params[index].name">
                      </b-form-select>
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        type="text"
                        :id="'input-' + index"
                        v-model="form.params[index].value"
                        required
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1">
                      <i class="fa fa-plus mt-2 cursor-pointer"
                         v-if="index === 0"
                         v-on:click="addParam()"
                      ></i>
                      <i class="fa fa-minus cursor-pointer"
                         v-else v-on:click="removeCurrentParam(index)"
                      ></i>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4" v-if="form.params[index].name === 'tags'">
                    <b-col>
                      <div>
                        <p>As tags use any of the following separated by comma:</p>
                        <ul>
                          <li>ssl</li>
                          <li>web</li>
                          <li>wordpress</li>
                          <li>basic</li>
                          <li>cipher</li>
                          <li>mail</li>
                        </ul>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-form>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        params: [
          {
            name: 'host',
            value: 'value',
          },
        ],
      },
      show: true,
      options: [
        {
          value: 'host',
          text: 'Host',
        },
        {
          value: 'port',
          text: 'Port',
        },
        {
          value: 'ip',
          text: 'IP Address',
        },
        {
          value: 'tags',
          text: 'Tags',
        },
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$store.dispatch(window.store.STORE_NEW_SCAN, this.form)
        .then((response) => {
          this.$router.push({ name: 'scan', params: { id: response.data.data.id } });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.namek = '';
      this.form.params = [
        {
          name: 'host',
          value: 'value',
        },
      ];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    addParam() {
      this.form.params.push({
        name: 'host',
        value: 'value',
      });
    },
    removeCurrentParam(index) {
      this.form.params.splice(index, 1);
    },
  },
};
</script>
